<template>
  <mega-card :title="$t('promocodes') + ':'">
    <span class="px-3 pt-3">
      {{ $t("promo_signups") }} - {{ total_signups }}
    </span>
    <div v-for="(promocode, id) in promocodes" :key="id">
      <mega-dropdown :archived="!!promocode.archived_at">
        <template #title>
          <span class="align-self-center ml-auto mr-auto"
            >{{ promocode.value }} ({{ promocode.invitees_count }})</span
          >
          <div class="ml-auto p-absolute" style="left: 5px; top: -30px">
            <i class="i-calendar small" />
            <small>{{
              $u2d(new Date(promocode.created_at), "DD.MM.YYYY")
            }}</small>
            <small v-show="promocode.archived_at"> — </small>
            <small v-if="promocode.archived_at">
              {{ $u2d(new Date(promocode.archived_at), "DD.MM.YYYY") }}</small
            >
            <small v-else>({{ $t("advert_active") }})</small>
          </div>
        </template>
        <div v-for="(element, el_id) in promocode.array" :key="el_id">
          <div class="d-flex py-2 pl-3">
            <router-link
              :to="{
                name: 'User',
                params: { id: element ? element.idt_user : '' }
              }"
              class="navbar-label text-dark mt-auto mb-auto w-100"
            >
              <mega-image
                class="rounded-full navbar-avatar mr-3"
                ratio="1x1"
                :src="element.avatar"
                style="overflow:hidden;"
              />
              <div class="content d-flex flex-column">
                <small class="strong text-overflow"
                  >{{ element.name || "- - -" }}
                  <IconBase
                    v-if="element.verified"
                    viewBox="0 0 312 311"
                    :width="15"
                    iconColor="#45C1C9"
                    ><UserVerified /></IconBase
                ></small>
                <small class="text-muted text-overflow">
                  <span
                    v-if="device"
                    :class="{ 'text-success': device === 'i-android' }"
                  >
                    <i :class="device"></i> -
                  </span>
                  {{ element.username }}
                </small>
              </div>
              <small class="ml-auto mr-2">
                {{ $u2d(element.a_time, "DD-MM-YYYY") }}
              </small>
            </router-link>
          </div>
        </div>
        <mega-loading
          :identifier="promocode.idt_invite_code"
          @infinite="fetchUsersList($event, promocode)"
          :forceUseInfiniteWrapper="true"
        ></mega-loading>
      </mega-dropdown>
    </div>
  </mega-card>
</template>

<script>
import IconBase from "../../../../components/icons/IconBase";
import UserVerified from "../../../../components/icons/set/UserVerified";
export default {
  name: "PromoCodes",
  components: {
    IconBase,
    UserVerified
  },
  props: {
    userData: Object,
    roundlikes: [Number, String],
    roundviews: [Number, String]
  },
  mounted() {
    this.$api.v2base
      .get("/user/influencers/codes", {
        params: {
          idt_user: this.userData.idt_user
        }
      })
      .then(res => {
        this.promocodes = res.data.reverse();
        this.promocodes.forEach(code => {
          this.$set(code, "array", []);
        });
        this.total_signups = this.promocodes
          .map(item => item.invitees_count)
          .reduce((acc, val) => acc + val);
      });
  },
  methods: {
    fetchUsersList($event, code) {
      console.log(code);
      this.$api.v2base
        .get("/user/influencers/invitees", {
          params: {
            idt_invite_code: code.idt_invite_code,
            limit: 5,
            offset: code.array.length
          }
        })
        .then(res => {
          if (res.data.invitees.length) {
            let promo = this.promocodes.find(
              promocode => promocode.idt_invite_code == code.idt_invite_code
            );
            $event.loaded();
            code.array.push(...res.data.invitees);
            // promo["offset"] = res.data.offset;
            console.log(promo);
          } else {
            $event.complete();
          }
        });
    }
  },
  data() {
    return {
      modal: false,
      promocodes: [],
      total_signups: ""
    };
  }
};
</script>

<style></style>
